








import { Component, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import { AdminRoutes } from "@/shared/router/admin";
import FormBase from "@/shared/classes/form/form-base";
import Field, { FieldSizes } from "@/shared/classes/form/field";
import { FieldTypes } from "@/shared/components/form/field-types";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import SelectField from "@/shared/classes/form/fields/Select/select-field";
import SelectItem from "@/shared/classes/form/fields/Select/select-item";
import { getLanguagesByVersion, getNodeApiUrlByVersion } from "@/config";
import FormField from "@/shared/components/form/FormField.vue";
import RiskTemplateForm from "@/admin/components/risk-templates/RiskTemplateForm.vue";
import ArrayField from "@/shared/classes/form/fields/array-field";
import localeHelper from "@/shared/helpers/locale.helper";
import { GlobalActions } from "@/shared/store/global/global.actions";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import { validateFields } from "@/shared/helpers/validate";
import Form from "@/shared/components/form/Form.vue";

@Component({
  components: { PageHeader, Form },
  methods: { __ }
})
export default class CoursesCreate extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint(`${getNodeApiUrlByVersion()}/courses`)
    .setTranslatable(true)
    .setFiles(true)
    .setFields([
      new Field()
        .setKey('name')
        .setTranslatable(true)
        .setTitle(__("admin.views.courses.form.name"))
        .isRequired(),
      new Field()
        .setType(FieldTypes.file)
        .setKey('picture')
        .setTitle(__('admin.views.courses.form.picture'))
        .setMeta({ type: 'image', accept: 'image/*' }),
      new SearchableField()
        .setKey("courseCategoryUuid")
        .setTitle(__("admin.views.courses.form.category"))
        .loadItems({
          endpoint: `${getNodeApiUrlByVersion()}/course-categories`,
          value: "uuid",
          title: "name",
          perPage: 20
        })
        .isRequired(),
      new SelectField()
        .setKey("isVisible")
        .setItems([
          new SelectItem()
            .setValue(true)
            .setTitle(__("admin.views.courses.form.visible-option")),
          new SelectItem()
            .setValue(false)
            .setTitle(__("admin.views.courses.form.invisible-option"))
        ])
        .setTitle(__("admin.views.courses.form.is-visible")),
    ])
    .setInitialValues({
      isVisible: true
    })
    .setSubmit({ text: __("general.create") })
    .setValidate(true, { onlyRegionLangRequired: true })
    .setOnSuccess(this.onSuccess);

  goBack(): void {
    this.$router.push({ name: AdminRoutes.coursesIndex });
  }

  private onSuccess(response: any): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('admin.views.courses.create.on-success'),
    });

    this.$router.push({ name: AdminRoutes.courseDetailPageOverview, params: { uuid: response.uuid } });
  }
}
